import * as React from 'react';

import { graphql } from 'gatsby';

import IndexLayout from '../layouts';

import Footer from '../components/Footer/Footer';
import SEO from '../components/SEO/SEO';

import PricingFaq from '../components/Pricing/Pricing/PricingFaq/PricingFaq';
import PricingHero from '../components/Pricing/Pricing/PricingHero/PricingHero';
import PricingSocialProof from '../components/Pricing/Pricing/PricingSocialProof/PricingSocialProof';
import PricingSpecs from '../components/Pricing/Pricing/PricingSpecs/PricingSpecs';

import IndexCTA from '../components/Index/IndexCTA/IndexCTA';
import { LocaleTypes } from '../data/locales';

export interface File {
  publicURL: string;
}

export interface PricingMethodType {
  alias?: string;
  name: string;
  icon: {
    localFile: File;
  };
}

export interface List {
  name: string;
  img: {
    localFile: File;
  };
}

export interface Price {
  title: string;
  subtitle: string;
  variable: number;
  fixed?: any;
  fixedCurrency?: any;
}

export interface PricingMethod {
  settlement: string;
  settlementTitle: string;
  pricingMethodType: PricingMethodType;
  listTitle: string;
  list: List[];
  pricing: Price[];
}

export interface Flag {
  localFile: File;
}

export interface ContentfulCountryPricing {
  name: string;
  code: string;
  pricingMethods: PricingMethod[];
  flag: Flag;
  taxName: string;
  transactionCap: string;
  monthlyMinimumAmount?: number;
  monthlyMinimumCurrency?: string;
  background: {
    localFile: {
      publicURL: string;
    };
  };
}

export interface Data {
  allContentfulCountryPricing: {
    edges: {
      node: {
        name: string;
        code: string;
        flag: Flag;
      }
    }[]
  };
  contentfulCountryPricing: ContentfulCountryPricing;
}

export interface PricingTemplateProps {
  data: Data;
  pathContext: {
    locale: LocaleTypes;
  }
  location: Location;
}

const PricingTemplate: React.SFC<PricingTemplateProps> = ({ data, pathContext, location }) => {
  const content = () => (
    <>
      <SEO titleId="pricing.title" titleValues={{country: data.contentfulCountryPricing.name}} />

      <PricingHero {...data} />

      <PricingSpecs />

      <PricingFaq />

      <PricingSocialProof />

      <IndexCTA />

      <Footer theme="primary" />
    </>
  );

  return (
    <IndexLayout
      render={ content }
      navbarTheme="light"
      locale={ pathContext.locale }
      location={location}
    />
  );

};

export default PricingTemplate;


export const query = graphql`
  query($code: String!, $locale: String!) {
    allContentfulCountryPricing(sort: {fields: code, order: ASC}, filter: {node_locale: {eq: $locale}}) {
      edges {
        node {
          name
          code
          flag {
            localFile {
              publicURL
            }
          }
        }
      }
    },
    contentfulCountryPricing(code: {eq: $code}, node_locale: {eq: $locale}) {
      name
      code
      monthlyMinimumAmount
      monthlyMinimumCurrency
      pricingMethods {
        settlement
        settlementTitle
        pricingMethodType {
          name
          alias
          icon {
            localFile {
              publicURL
            }
          }
        }
        listTitle
        list {
          name
          img {
            localFile {
              publicURL
            }
          }
        }
        pricing {
          title
          subtitle
          variable
          fixed
          fixedCurrency
        }
      }
      flag {
        localFile {
          publicURL
        }
      }
      taxName
      transactionCap
      background {
        localFile {
          publicURL
        }
      }
    }
  }
`;
