import React from 'react';
import Swiper from 'react-id-swiper';
import { FormattedMessage } from 'react-intl';

import testimony1Img from './assets/images/brunoOcampo.jpg';
import testimony2Img from './assets/images/danielArevalo.jpg';
import testimony3Img from './assets/images/mariaCalderon.png';

import bgImg from './assets/images/socialProofBg.svg';

import './styles.scss';

const testimonies = [
  {
    content:
      <>
        <FormattedMessage id="pricing.testimonials.testimony1.p1" />
        { ' ' }
        <span className="customer-testimony__content--highlight">
        <FormattedMessage id="pricing.testimonials.testimony1.p2" />
        </span>
        <FormattedMessage id="pricing.testimonials.testimony1.p3" />
        { ' ' }
        <span className="customer-testimony__content--highlight">
        <FormattedMessage id="pricing.testimonials.testimony1.p4" />
        </span>
        <FormattedMessage id="pricing.testimonials.testimony1.p5" />
      </>,
    img: testimony1Img,
    name: 'Bruno Ocampo',
    role: 'Co-Founder & CEO, Mi Águila',
  },
  {
    content:
      <>
        <FormattedMessage id="pricing.testimonials.testimony2.p1" />
        <span className="customer-testimony__content--highlight">
        <FormattedMessage id="pricing.testimonials.testimony2.p2" />
        </span> 
        <FormattedMessage id="pricing.testimonials.testimony2.p3" />
      </>,
    img: testimony3Img,
    name: 'María Fernanda Calderón',
    role: 'Gerente de Experiencia & Digitalización - Telefónica Ecuador',
  }
]

const params = {
  autoplay: {
    delay: 5000,
    disableOnInteraction: false
  },
  grabCursor: true,
  loop: true,
  pagination: {
    clickable: true,
    el: '.swiper-pagination.customer-testimony-pagination',
    type: 'bullets',
  },
}

class PricingSocialProof extends React.Component {

  constructor(props: any) {
    super(props);
  }

  public render() {
    return (
      <section
        className="customer-testimony-section py-layout-7"
        style={{ backgroundImage: `url(${bgImg})` }}
      >
        <div className="container">
          <Swiper {...params}>
            {
              testimonies.map((testimony) =>
                <div className="customer-testimony" key={testimony.name}>
                  <p className="customer-testimony__content">
                   {testimony.content}
                  </p>
                  <div className="customer-testimony__author">
                    <img src={testimony.img} className="customer-testimony__author__avatar"/>
                    <div>
                      <h4 className="text-primary">{testimony.name}</h4>
                      <p className="lead mb-0 text-dark-gray-3">{testimony.role}</p>
                    </div>
                  </div>
                </div>
              )
            }
          </Swiper>
        </div>
      </section>
    );
  }
}

export default PricingSocialProof;
