import React, { useState } from 'react'
import useOnClickOutside from '../../../../hooks/useOnClickOutside'
import { ContentfulCountryPricing } from '../../../../templates/pricingTemplate'
import LocalizedLink from '../../../LocalizedLink/LocalizedLink'

interface PricingSelectorProps {
  selectedCountry: ContentfulCountryPricing
  pricingData: any
}

const PricingSelector: React.FC<PricingSelectorProps> = ({selectedCountry, pricingData}) => {
  const ref = React.createRef<HTMLDivElement>()
  const [dropdownActive, setDropdownActive] = useState(false)

  useOnClickOutside(ref, () => setDropdownActive(false))

  return (
    <div
      className="dropdown dropdown-country-selector d-inline-block"
      ref={ref}
    >
      <a
        className="dropdown-toggle"
        id="accountMenu"
        onClick={() => setDropdownActive(!dropdownActive)}
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded={dropdownActive}
      >
        {selectedCountry.name}
      </a>
      <div className={`dropdown-menu  ${dropdownActive ? 'show' : ''}`} >
        {
          pricingData.map((edge: any) => (
            <LocalizedLink
              to={`/pricing/${edge.node.code.toLowerCase()}`}
              className="dropdown-item"
              activeClassName="dropdown-item--active"
              key={edge.node.code}
            >
              <img height="24" width="24" src={edge.node.flag.localFile.publicURL} className="mr-4 country-ball" alt={`${edge.node.name} flag`} />{edge.node.name}
            </LocalizedLink>
          ))
        }
      </div>
    </div>
  )
}

export default PricingSelector
