import { OutboundLink } from 'gatsby-plugin-google-gtag';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import docsIcon from './assets/images/docsIcon.svg';
import slantImg from './assets/images/slant.svg';

import './styles.scss';

const pricingFaq: React.FC = ({ children }) => (
  <section className="pricing-faq">
    <div className="pt-layout-6 pb-layout-3 bg-light-gray-1-5">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 mt-5 mt-lg-0">
            <div className="pricing-faq__question">
              <div className="pricing-faq__question__icon">
                <span>?</span>
              </div>
              <div className="pricing-faq__question__content">
                <h4 className="mb-4 text-dark-gray-3">
                  <FormattedMessage id="pricing.faq.question_1.q"/>
                </h4>
                <p className="text-dark-gray-3">
                  <FormattedMessage id="pricing.faq.question_1.a"/>
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-6 mt-5 mt-lg-0">
            <div className="pricing-faq__question">
              <div className="pricing-faq__question__icon">
                <span>?</span>
              </div>
              <div className="pricing-faq__question__content">
              <h4 className="mb-4 text-dark-gray-3">
                  <FormattedMessage id="pricing.faq.question_2.q"/>
                </h4>
                <p className="text-dark-gray-3">
                  <FormattedMessage id="pricing.faq.question_2.a"/>
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-6 mt-5 mt-lg-0">
            <div className="pricing-faq__question">
              <div className="pricing-faq__question__icon">
                <span>?</span>
              </div>
              <div className="pricing-faq__question__content">
              <h4 className="mb-4 text-dark-gray-3">
                  <FormattedMessage id="pricing.faq.question_3.q"/>
                </h4>
                <p className="text-dark-gray-3">
                  <FormattedMessage id="pricing.faq.question_3.a"/>
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-6 mt-5 mt-lg-0">
            <div className="pricing-faq__question">
              <div className="pricing-faq__question__icon">
                <span>?</span>
              </div>
              <div className="pricing-faq__question__content">
              <h4 className="mb-4 text-dark-gray-3">
                  <FormattedMessage id="pricing.faq.question_4.q"/>
                </h4>
                <p className="text-dark-gray-3">
                  <FormattedMessage id="pricing.faq.question_4.a"/>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="text-center mt-layout-4">
          <OutboundLink className="pricing-faq__docs-link" href="https://soporte.kushkipagos.com/hc/es/categories/203262248-Preguntas-Frecuentes-" target="_blank" rel="noopener">
            <img src={docsIcon} className="pricing-faq__docs-link__icon" /> <FormattedMessage id="pricing.faq.button"/>
          </OutboundLink>
        </div>
      </div>
    </div>
    <img className="pricing-faq__slant" src={slantImg} />
  </section>
);

export default pricingFaq;
