import React from 'react';
import { FormattedMessage } from 'react-intl';
import { defaultFormatValues } from '../../../../data/defaultFormat';

import LocalizedLink from '../../../LocalizedLink/LocalizedLink';

import NumberFormat from 'react-number-format';
import ReactTooltip from 'react-tooltip';

import './styles.scss';

import {
  Data,
  Price,
} from '../../../../templates/pricingTemplate';
import PricingSelector from './PricingSelector';
import SalesButton from '../../../SalesButton/SalesButton';

// tslint:disable-next-line: no-empty-interface
interface PricingHeroProps extends Data {}

interface PricingHeroState {
  countrySelectorDropdownShow: boolean;
}

class PricingHero extends React.Component<PricingHeroProps, PricingHeroState> {
  constructor(props: any) {
    super(props);

    this.toggleCountrySelectorDropdown = this.toggleCountrySelectorDropdown.bind(
      this
    );

    this.state = {
      countrySelectorDropdownShow: false,
    };
  }

  public toggleCountrySelectorDropdown() {
    this.setState({
      countrySelectorDropdownShow: !this.state.countrySelectorDropdownShow,
    });
  }

  public priceDisplay(price: Price, key: number) {
    return (
      <div
        className={`method-pricing__price ${
          price.title && 'method-pricing__price--withTitle'
        }`}
        key={key}
      >
        {price.title && <span className="price__title">{price.title}</span>}
        {this.priceAmountDisplay(price)}
        {price.subtitle && (
          <span className="price__subtitle">{price.subtitle}</span>
        )}
      </div>
    );
  }
  public numberWithCommas(x: number) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  public render() {
    return (
      <section className="hero hero-pricing section-xl">
        <div className="container">
          <div className="row align-items-center">
            <div className="col text-center">
              <h1 className="display-4 mt-5 text-light">
                <FormattedMessage
                  id="pricing.hero.title"
                  values={{
                    countrySelector: (
                      <PricingSelector
                        pricingData={
                          this.props.allContentfulCountryPricing.edges
                        }
                        selectedCountry={this.props.contentfulCountryPricing}
                      />
                    ),
                  }}
                />
              </h1>
            </div>
          </div>

          <div className="row">
            <div className="col-md-10 offset-md-1">
              <div className="container-pricing">
                <div className="pricing-container-card pricing-container-card--details">
                  <div className="pricing-card">
                    <div className="detail-top">
                      <h3 className="text-primary">
                        <FormattedMessage id="pricing.hero.monthly_minimum.title" />
                      </h3>
                      <div className="price-display">
                        <h1 className="display-2">
                          {this.numberWithCommas(this.props.contentfulCountryPricing.monthlyMinimumAmount)}
                        </h1>
                        <h2>
                          {this.props.contentfulCountryPricing.monthlyMinimumCurrency}
                        </h2>
                      </div>
                      <p className="minimum-text">
                        <FormattedMessage id="pricing.hero.monthly_minimum.description"
                          values={{
                            ...defaultFormatValues,
                          }}
                        />
                      </p>
                    </div>
                    <div className="detail-bottom">
                      <h4 className="text-primary">
                        <FormattedMessage id="pricing.hero.cta.title" />
                      </h4>
                      <p>
                        <FormattedMessage id="pricing.hero.cta.subtitle"
                          values={{
                            ...defaultFormatValues,
                            amount: this.props.contentfulCountryPricing.transactionCap
                          }}
                        />
                      </p>
                      <div className="d-inline-block">
                        <SalesButton big={true} className="btn-primary" positionTag="top">
                          <FormattedMessage id="buttons.sales"/>
                        </SalesButton>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="pricing-container-card pricing-container-card--methods">
                  <div className="methods-card">
                    <div className="methods-card__title py-2">
                      <h4 className="text-primary">
                        <FormattedMessage id="pricing.hero.title_method" />
                      </h4>
                    </div>
                    {this.props.contentfulCountryPricing.pricingMethods.map(
                      (method) => (
                        <>
                          <div className="methods-card__row">
                            <div className="d-flex">
                              <img
                                  className="pricing-method-icon"
                                  src={
                                    method.pricingMethodType.icon.localFile.publicURL
                                  }
                                  alt="card icon"
                                />
                                <div className="ml-2">
                                  <h5 className="mb-0">
                                    {method.pricingMethodType.name}
                                  </h5>
                                  <div className="methods-card__row__payment-methods mt-4">
                                    <div className="method-item-images">
                                      {method.list.map((item) => (
                                        <div
                                          key={`${
                                            this.props.contentfulCountryPricing.name
                                          }-${this.toCamelCase(item.name)}`}
                                        >
                                          <img
                                            src={
                                              item.img !== null
                                                ? item.img.localFile.publicURL
                                                : ''
                                            }
                                            alt={item.name}
                                            data-tip="true"
                                            data-for={`${
                                              this.props.contentfulCountryPricing.name
                                            }-tooltip-${this.toCamelCase(item.name)}`}
                                          />
                                          <ReactTooltip
                                            id={`${
                                              this.props.contentfulCountryPricing.name
                                            }-tooltip-${this.toCamelCase(item.name)}`}
                                            effect="float"
                                          >
                                            {item.name}
                                          </ReactTooltip>
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                </div>
                            </div>

                          </div>
                        </>
                      )
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
        <div className="mt-7 text-center text-dark-gray-2">
          <h6>
            <span className="text-kushki-green">*</span>
            <FormattedMessage
              id="pricing.hero.disclaimer"
              values={{
                taxName: this.props.contentfulCountryPricing.taxName,
              }}
            />
          </h6>
        </div>
        <div
          className="hero-pricing-bg"
          style={{
            backgroundImage: `url(${this.props.contentfulCountryPricing.background.localFile.publicURL})`,
          }}
        />
      </section>
    );
  }

  private priceAmountDisplay(price: Price) {
    let amount;

    if (price.variable && price.fixed) {
      amount = (
        <div className="price--variablePlusFixed">
          <span className="price__variable">
            <NumberFormat
              decimalSeparator=","
              thousandSeparator="."
              value={price.variable}
              displayType={'text'}
              suffix="%"
            />
          </span>
          <span className="price__plusSign">+</span>
          <span className="price__fixed">
            <NumberFormat
              decimalSeparator=","
              thousandSeparator="."
              value={price.fixed}
              displayType={'text'}
            />
          </span>
          <span className="price__currency">{price.fixedCurrency}</span>
        </div>
      );
    } else if (price.variable) {
      amount = (
        <div className="price--single">
          <span className="price__variable">
            <NumberFormat
              decimalSeparator=","
              thousandSeparator="."
              value={price.variable}
              displayType={'text'}
              suffix="%"
            />
          </span>
        </div>
      );
    } else {
      amount = (
        <div className="price--single">
          <span className="price__fixed">
            <NumberFormat
              decimalSeparator=","
              thousandSeparator="."
              value={price.fixed ? price.fixed : undefined}
              displayType={'text'}
            />
          </span>
          <span className="price__currency">{price.fixedCurrency}</span>
        </div>
      );
    }

    return amount;
  }

  private toCamelCase(str: string) {
    return str
      .replace(/\s(.)/g, (result) => result.toUpperCase())
      .replace(/\s/g, '')
      .replace(/^(.)/, (result) => result.toLowerCase());
  }
}

export default PricingHero;
