import React from 'react';
import { FormattedMessage } from 'react-intl';

import antifraudIcon from './assets/images/antifraudIcon.svg';
import dashboardIcon from './assets/images/dashboardIcon.svg';
import experienceIcon from './assets/images/experienceIcon.svg';
import integrationIcon from './assets/images/integrationIcon.svg';
import internationalIcon from './assets/images/internationalIcon.svg';
import subscriptionsIcon from './assets/images/subscriptionsIcon.svg';

import './styles.scss';

const pricingSpecs: React.FC = ({ children }) => (
  <>
  <section className="my-layout-7">
    <div className="container">
      <div className="text-center mb-layout-4">
        <h2 className="text-primary mb-5">
          <FormattedMessage id="pricing.whats_included.title"/>
        </h2>
        <p className="lead">
        <FormattedMessage id="pricing.whats_included.subtitle"/>
        </p>
      </div>
      <div className="row">
        <div className="col-lg-4 col-md-6">
          <img src={internationalIcon} alt="international payments img" className="mb-4 mt-5 mt-md-0" />
          <h4 className="mb-4">
            <FormattedMessage id="pricing.whats_included.feature_1.title"/>
          </h4>
          <p>
            <FormattedMessage id="pricing.whats_included.feature_1.subtitle"/>
          </p>
        </div>
        <div className="col-lg-4 col-md-6">
          <img src={antifraudIcon} alt="antifraud img" className="mb-4 mt-5 mt-md-0" />
          <h4 className="mb-4">
            <FormattedMessage id="pricing.whats_included.feature_2.title"/>
          </h4>
          <p>
            <FormattedMessage id="pricing.whats_included.feature_2.subtitle"/>
          </p>
        </div>
        <div className="col-lg-4 col-md-6">
          <img src={integrationIcon} alt="integration img" className="mb-4 mt-5 mt-md-0" />
          <h4 className="mb-4">
            <FormattedMessage id="pricing.whats_included.feature_3.title"/>
          </h4>
          <p>
            <FormattedMessage id="pricing.whats_included.feature_3.subtitle"/>
          </p>
        </div>
        <div className="col-lg-4 col-md-6">
          <img src={experienceIcon} alt="payment experience img" className="mb-4 mt-5 mt-md-0" />
          <h4 className="mb-4">
            <FormattedMessage id="pricing.whats_included.feature_4.title"/>
          </h4>
          <p>
            <FormattedMessage id="pricing.whats_included.feature_4.subtitle"/>
          </p>
        </div>
        <div className="col-lg-4 col-md-6">
          <img src={dashboardIcon} alt="dashboard img" className="mb-4 mt-5 mt-md-0" />
          <h4 className="mb-4">
            <FormattedMessage id="pricing.whats_included.feature_5.title"/>
          </h4>
          <p>
            <FormattedMessage id="pricing.whats_included.feature_5.subtitle"/>
          </p>
        </div>
        <div className="col-lg-4 col-md-6">
          <img src={subscriptionsIcon} alt="subscriptions img" className="mb-4 mt-5 mt-md-0" />
          <h4 className="mb-4">
            <FormattedMessage id="pricing.whats_included.feature_6.title"/>
          </h4>
          <p>
            <FormattedMessage id="pricing.whats_included.feature_6.subtitle"/>
          </p>
        </div>
      </div>
    </div>
  </section>
  </>
);

export default pricingSpecs;
